.banner_1 {
    background-image: url('../images/Airstash-login-back.jpg');
    min-height: 480px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.customBanner{
    margin-top: -386px;
    margin-bottom: 200px;
}
.customBanner-div{
    z-index: 9999;
}
.customBtn{
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 28px;
}
.img-hr{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    text-align: center;
}
.img-p{
    font-size: 0.62rem;
    letter-spacing: 0.06em;
    font-weight: 500;
}
.customUl{
    font-size: 12px;
    letter-spacing: 0.06rem;
}
.custCarousel{
    top: -80px;
}
.custCarousel .carousel-indicators li{
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background-color: #184870 !important;
}
.custCarousel .carousel-indicators .active {
    background-color: #1EDFFE !important;
}
.customNav{
    z-index: 9999!important;
}
.navbtn{
    border: 1px solid #fff !important;
    border-radius: 20px !important;
    font-size: 12px;
    letter-spacing: 0.06rem;
}
.logo {
    background-image: url("../images/Logo.png");
    height: 50px;
    width: 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.customHeader{
    letter-spacing: 0.15rem;
    font-weight: 600;
    color: #242945;
}
.customSpan{
    font-size: 40px;
    color: #1FC366;
    font-weight: 700;
}
.customSpan-2{
    font-size: 40px;
    color: #1F9CDE;
    font-weight: 700;
}
.customSpan-3{
    font-size: 40px;
    color: #FF4342;
    font-weight: 700;
}
.customPara{
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    text-align: justify;
}
.Parent_img{
    height: 296px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}
.Parent_img-2{
    background-position: left;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
}
.Parent_img-3{
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Child_img-2{
    height: 206px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Child_img-3{
    height: 300px;
    width: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.Child_img{
    height: 206px;
    width: 468px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.custCarousel-h{
    letter-spacing: 0.06rem;
}
.custCarousel-span{
    font-size: 46px !important;
    color: #1EC365;
}
.custCarousel-span2{
    font-size: 46px;
    color: #1FDEFF;
}
.custCarousel-h4{
   margin-left: 240px;
}
.custCarousel-p{
     margin-left: 240px;
     font-size: 14px;
     letter-spacing: 0.07rem;
}
.custCarousel-btn{
    background-color: #FF4342 !important;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.05rem;
    margin-left: 530px;
}
#ParentImg {
    background-image: url("../images/tab-2.png");
}
#ParentImg-2 {
    background-image: url("../images/tab-4.png");
}
#ParentImg-3 {
    background-image: url("../images/tab-6.png");
}
#ChildImg {
    background-image: url("../images/tab-1.png");
}
#ChildImg-2 {
    background-image: url("../images/tab-3.png");
}
#ChildImg-3 {
    background-image: url("../images/tab-5.png");
}
.bgColor{
    background-color: #E9E9E9
}
.btn-platform{
    border: 1px solid black;
}
.btn-platform i{
   font-size: 26px;
}
.btn-platform h5{
    font-size: 0.25rem;
    letter-spacing: 0.05rem;
}
.btn-i{
     font-size: 32px !important;
}
.bgColor-img {
    background-image: url("../images/tab-7.png");
    min-height: 324px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 252px;
    margin-top: -80px;
}
.bgColor-Logo {
    background-image: url("../images/Logo.png");
    height: 180px;
    width: 390px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.bgColor-span{
    font-size: 14px;
    letter-spacing: 0.05rem;
}
.ParentFooter{
    margin:10rem 0rem;
}
.ParentFooter-hr{
   color: #05A74A;
   letter-spacing: 0.05rem;
}
.ParentFooter-p{
    font-size: 1.2rem;
    letter-spacing: 0.06rem;
    text-align: justify;
}
.FooterBG {
    background-color: #111633;
}
.FooterLogo {
    background-image: url("../images/Logo.png");
    height: 80px;
    width: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.FooterDigitalLogo {
    background-image: url("../images/Dair-logo.png");
    height: 80px;
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
.parentFooterLogo {
    border-bottom: 1px solid #3FA7CE;
    border-top: 1px solid #3FA7CE;
    border-left: none;
    border-right: none;
}
.FooterSpan {
    font-size: 12px;
    letter-spacing: 0.05rem;
    color: #fff !important;
}
.FooterLi {
    background-color: #111633 !important;
    padding: 0.1rem 0 !important;
    font-size: 10px !important;
    letter-spacing: 0.05rem;
    border: none !important;
    color: #fff !important
}
.FooterInput {
    padding: 0rem 1.8rem !important;
}
.FooterBtn {
    padding: 0rem 1.8rem !important;
    letter-spacing: 0.05rem;
    font-size: 12px !important;
    color: white !important;
    background-color: #3FA7CE !important;
}
.FooterIcon{
    height: 30px;
    background-position: center;
    background-size: contain;
    width: 30px;
    background-repeat: no-repeat;
}
#FooterIcon_1 {
    background-image: url("../images/icon-13.png");
}
#FooterIcon_2 {
    background-image: url("../images/icon-12.png");
}
#FooterIcon_3 {
    background-image: url("../images/icon-11.png");
}
#FooterIcon_4 {
    background-image: url("../images/icon-10.png");
}
.Footer_child{
	background-color: #001C4B;
}
.Footer_child_pr{
	font-size: 6px;
    margin-bottom: 0px !important;
    letter-spacing: 0.08rem;
}
@media (max-width: 1199.98px) {
    .bgColor-img {
        background-image: url("../images/tab-7.png");
        min-height: 324px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center !important;
        width: unset;
        margin-top: 0px;
        position: unset;
    }
    .bgColor-Logo {
        background-image: url("../images/Logo.png");
        height: 180px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
.custCarousel-h4{
   margin-left: 0px;
   text-align: center;
}
.custCarousel-p{
     margin-left: 0px;
     font-size: 14px;
     letter-spacing: 0.07rem;
      text-align: center;
}
.custCarousel-btn{
    background-color: #FF4342 !important;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0.05rem;
    margin-left: 0px;
    text-align: center;
}
.cust-btn{
    text-align: center;
 }
 .custCarousel-h{
    letter-spacing: 0.06rem;
    text-align: center;
}
.custCarousel {
    top: 0px; 
    }
 .customUl-color li a{
    color: black !important;
 }
.customBanner {
    margin-top: -348px;
    margin-bottom: 230px;
}
    .Child_img-3 {
        width: 100%;
    }
}

.cust-Container {
    overflow: hidden !important;
}

.imgEffectOne {
    animation: slideL 0.7s ease-in;
}
.imgEffectTwo {
    animation: slideR 0.7s ease-in;
}
.imgEffectThree {
    animation: slideU 0.7s ease-in;
}

@keyframes slideU {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translate(0px);
    }
}

@keyframes slideR {
    0% {
        transform: translateX(500px);
    }
    100% {
        transform: translate(0px);
    }
}

@keyframes slideL {
    0% {
      transform: translateX(-500px);
    }
    100% {
        transform:translate(0px);
    }
}

