body {
  font-family: "Montserrat", sans-serif;
}
.login-banner {
  background-image: url("../images/Airstash-login-back.jpg");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-banner {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.positive {
  color: #4bbe6e !important;
}
.negative {
  color: #f34c4c !important;
}
.login-logo {
  background-image: url("../images/Airstash-login-logo.png");
  height: 430px;
  width: 590px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cust-form {
  border-radius: 4rem;
  background-color: #fff;
  width: 75%;
  .form-control {
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #ced4da !important;
      outline: 0;
      box-shadow: none !important;
    }
  }
}
.cust-span {
  color: #949494;
  letter-spacing: 0.06rem;
}
.cust-input {
  border-bottom: 1px solid #ced4da !important;
  letter-spacing: 0.06rem;
  border-radius: 0px !important;
}
.cust-btn {
  background-color: #232846;
  letter-spacing: 2px;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 600;
}
.marketData {
  background-image: url(../images/marketdataBG.jpg);
  height: 130vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
}
.trade-img {
  background-image: url(../images/tradedataBG.png);
  height: 106vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
}
.logo {
  background-image: url("../images/airstash-logo1.png");
  height: 50px;
  width: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.custTable {
  margin-bottom: 0px !important;
  tbody {
    td {
      border-top: 0px !important;
      padding: 0.3rem !important;
    }
    th {
      padding-left: 0rem !important;
      border-top: 0px !important;
    }
    tr {
      font-size: 0.8rem !important;
      letter-spacing: 0.05rem !important;
    }
  }
  thead {
    tr {
      th {
        padding-left: 0rem !important;
      }
      font-size: 0.7rem !important;
    }
    td {
      border-top: 0px !important;
    }
    th {
      border-top: 0px !important;
    }
  }
}
.td1 {
  background-color: #3eb865;
  color: #fff !important;
  text-align: center;
}
.td2 {
  background-color: #3c99d2;
  color: #fff !important;
  text-align: center;
}
.td3 {
  background-color: #f3494a;
  color: #fff !important;
  text-align: center;
}
.td4 {
  background-color: #242947;
  color: #fff !important;
  text-align: center;
}
.custNav {
  float: right;
}
.cust-cardBody {
  overflow-y: auto;
}
.customBtn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 28px;
}
.customUl {
  font-size: 0.65rem;
  letter-spacing: 0.06rem;
}
.customNav {
  background-color: #242947;
}
.navbtn {
  border: 1px solid #fff !important;
  border-radius: 20px !important;
  font-size: 12px;
  letter-spacing: 0.06rem;
}
.header {
  background-color: #e1e1e1;
  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 6rem !important;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}
.customSpan {
  color: #3c99d3;
  letter-spacing: 0.06rem;
  font-weight: 600;
  font-size: 14px;
}
.header-dropdown {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 0px !important;
  letter-spacing: 0.06rem;
}
.banner {
  background-image: url("../images/Banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wrapper {
  background-color: #efefef;
}
.wrapperCard {
  background-color: #e1e1e1;
}
.custList {
  background-color: #f3494a !important;
}
.custList-2 {
  background-color: #3eb865;
}
.custSpan2 {
  font-size: 0.6rem;
  font-weight: 600;
}
.custSpan-1 {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.05rem;
}
.custSpan-3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 2.3;
}
.custCol {
  line-height: 1rem;
}
.custIcon {
  color: #bebebe !important;
}
.mediaHeader {
  color: #3f99d3;
}
.mediaPara {
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
}
.subMedia {
  max-height: 216px;
  overflow-y: auto;
}
.balanceTable {
  thead {
    tr {
      text-align: end;
      font-size: 11px;
      letter-spacing: 0.05rem;
    }
  }
  tbody {
    tr {
      font-size: 0.75rem;
    }
    th {
      font-size: 11px;
      text-align: end;
      padding: 0rem 1.5rem !important;
      font-weight: 400 !important;
      letter-spacing: 0.05rem;
    }
    td {
      font-size: 0.75rem;
      text-align: end;
      padding: 0rem 1.5rem !important;
      letter-spacing: 0.05rem;
    }
    libality {
      th {
        padding: 3rem !important;
      }
    }
  }
}
.balanceTableColor {
  color: #50be71 !important;
}
.FooterBG {
  background-color: #cecece;
}
.FooterLogo {
  background-image: url("../images/airstash-logo2.png");
  height: 65px;
  width: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.FooterLogo-2 {
  background-image: url("../images/Dair-logo.png");
  height: 60px;
  width: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.socialIcons {
  height: 100px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#fb {
  background-image: url("../images/facebook.png");
}
#tw {
  background-image: url("../images/twitter.png");
}
#in {
  background-image: url("../images/indeed.png");
}
#insta {
  background-image: url("../images/instagram.png");
}
.innerCol {
  background-color: #fff;
}
.innnerH1 {
  font-size: 0.85rem !important;
}
.innerSpan1 {
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.innnerH2 {
  font-size: 0.65rem !important;
}
.innerdown {
  font-size: 1.5rem !important;
  color: #f04a4a !important;
}
.innerup {
  font-size: 1.5rem !important;
  color: #3eb765 !important;
}
.innerSpan2 {
  font-size: 0.6rem;
  color: #0485fd;
  letter-spacing: 0.05rem;
  font-weight: 600;
}
.custom-table {
  thead {
    tr {
      th {
        text-align: center;
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: bold;
        color: #495057;
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: bold;
        color: #495057;
      }
    }
  }
}
nav#footer-nav {
  .nav-link {
    display: block;
    padding: 0 0.5rem;
  }
}
.nav-pills {
  .nav-link.active {
    color: #fff;
    background-color: #222744;
    border-radius: 0;
  }
  .show {
    > .nav-link {
      color: #fff;
      background-color: #222744;
      border-radius: 0;
    }
  }
  .nav-item {
    a {
      color: #222744;
      text-decoration: none;
      background-color: transparent;
      font-size: 10px;
      font-weight: 600;
    }
  }
}
.blue-font {
  color: #3c99d4;
}
@media (max-width: 1199.98px) {
  .inner-banner {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .login-logo {
    width: 300px;
    height: 150px;
  }
  .custNav {
    float: unset;
  }
  .custSize {
    font-size: 0.65em !important;
  }
  .cust-span {
    color: #949494;
    letter-spacing: 0.06rem;
    font-size: 18px;
  }
  .FooterLogo {
    height: 90px !important;
    width: 100% !important;
  }
  .FooterLogo-2 {
    background-position: center !important;
  }
  .customSpan {
    font-size: 0.65rem;
  }
  .custTable {
    margin: 0rem 2rem;
  }
}

.new-custombg {
  background-color: #F5F5F5;
  .text-white {
    color: #767780 !important;
    font-weight: 600;
  }
  .nav-link.active {
    color: #fff !important;
    background-color: #204d81;
    border-radius: 0;
  }
}
