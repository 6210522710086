.login-banner {
    .inner-banner {
        .text-danger {
            color: #b60a1a!important;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 1px;
        }
    }
}
