.table {
  white-space: nowrap;
  thead {
    tr {
      th {
        font-size: 11px;
        letter-spacing: 1px;
        font-weight: 700;
        color: #495057;
        border-top: none;
        padding: 0.4rem;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 11px;
        letter-spacing: 1px;
        border-top: 1px dashed #d8d8d8 !important;
        color: #495057;
        padding: 0.4rem;
      }
    }
  }
}

.card-header {
  letter-spacing: 0.06rem;
  font-weight: 600;
  font-size: 14px;
  color: #3c99d4;
  padding: 0.5rem 0.5rem !important;
}
.futures-table {
  .table-responsive {
    height: 355px !important;
  }
}
.casedeposits-table{
  .table-responsive {
    height: 160px !important;
  }
}
.table-responsive {
  height: 225px !important;
}

.table-height {
  .table-responsive {
    height: 175px !important;
  }
}
.date-custom {
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 5px;
  font-size: 11px;
  &:focus {
    outline: #495057;
  }
}
