.btn-white {
  .btn-primary {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
  }

  .btn {
    padding: 0;
    font-size: 12px;
    line-height: 1.5;
  }
  i {
    padding: 0.5rem 0;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    box-shadow: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    box-shadow: none;
  }

  .btn-primary:hover {
    background-color: #F5F5F5;
    border-color: #F5F5F5;
    box-shadow: none;
  }

  .dropdown-toggle::after {
    vertical-align: 0.055em;
  }
}
.logo1 {
  background-image: url("../../../assets/images/airstash-logo1.png") !important;
}

.Footer-bg {
  background-color: #cecece;
  .socialIcons {
    display: block !important;
  }
  .Footer-logo {
    background-image: url("../../../assets/images/airstash-logo2.png");
    height: 65px;
  }
}
