.customBanner {
  .btn-register {
    background-color: transparent !important;
  }
}
.custCarousel {
  .carousel-control-prev {
    display: none;
  }
  .carousel-control-next {
    display: none;
  }
}
