@import "~bootstrap/scss/bootstrap.scss";
@import "~font-awesome/css/font-awesome.min.css";
@import "./assets/scss/main.scss";
@import "./assets/scss/landing.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css");

.rdg-header-row {
  background-color: #fff;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #495057;
  border-top: none;
  .rdg-cell {
    border-right: none;
  }
}
.rdg-row {
  .rdg-cell {
    background-color: #fff;
    border-bottom-style: dashed;
    border-right: none;
    font-size: 11px;
    color: #495057;
    text-align: center;
  }
}

.rdg-header-row {
  text-align: center;
}